div.portfolio-content {
  height: 100%;
  display: flex;
  flex-flow: column;
}

div.portfolio-input {
  margin: 0 auto;
  width: 100%;
  flex: 0 1 auto;
}

div.portfolio-input-dates {
    display: inline-flex;
}

div.portfolio-chart {
  margin: 0 auto;
  flex: 1 0 auto;
  width: 100%;
  position: relative;
}

div.portfolio-chart-holder {
  position: absolute;
  height: 100%;
  width: 100%;
}

div.portfolio-table {
  margin: 0 auto;
  display: flex;
  flex: 0 1 auto;
}


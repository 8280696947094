div#finance {
  height: 100%;
  display: flex;
  flex-flow: column;
}
div#finance-topbar {
  flex: 0 0 auto;
}
div#finance-content {
  flex: 1 1 auto;
}

div.loading-screen {
  z-index: 5;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #0008;
  display: flex;
  flex-flow: column;
}

span.loading-text {
  font-size: xxx-large;
  text-shadow: #fff 0px 0px 5px;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  padding: 5em;
}

div.loading-animation {
  justify-content: center;
}

div.portfolio-content {
  height: 100%;
  display: flex;
  flex-flow: column;
}

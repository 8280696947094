.pct_zero, .dol_zero {
    color: #000;
}
.pct_neg, .dol_neg {
    color: #800;
    font-weight: bold;
}
.pct_pos, .dol_pos {
    color: #080;
    font-weight: bold;
}
